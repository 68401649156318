<template>
  <ClCardRow :label="label">
    <template #content>
      <ul class="zq--list" v-if="tags">
        <li
          v-for="(group, index) in tags"
          :key="index"
          class="group__colored mr-2"
        >
          {{ group }}
        </li>
      </ul>
    </template>
  </ClCardRow>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Tags',
    },
    tags: Array,
  },
};
</script>
